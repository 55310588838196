<template>
  <transition name="flixFadeIn">
    <div :key="open">
      <div class="flix-form-group" v-if="!open && !deleted">
        <a href="#" class="flix-btn flix-btn-danger flix-btn-xs" @click.prevent="open = !open">
          <flixIcon id="bin" />
        </a>
      </div>
      <div v-if="open">
        <span class="flix-text-danger">{{ $t('message.delete') }}?</span> <a href="#" class="flix-btn flix-btn-xs flix-btn-default" @click.prevent="function () { setDelete() }">{{ $t('message.yes') }}</a> <a class="flix-btn flix-btn-xs flix-btn-default" href="#" @click.prevent="open = false">{{ $t('message.no') }}</a>
      </div>
      <transition name="flixFadeIn">
        <div :key="deleted" v-if="deleted">
          <div class="flix-alert flix-alert-success">{{ $t('message.successfullDeleted') }}</div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  components: {},
  props: {
    callback: {
      type: Function,
      default () { return function () {} }
    }
  },
  data () {
    return {
      open: false,
      deleted: false
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setDelete () {
      this.callback()
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
